<template>
  <div class="about">
    <div v-html="general.aboutText" class="aboutText"></div>
    <div id="cvCont">
      <div v-for="cat in general.cv" :key="cat.id" class="category">
        <div v-html="cat.category" class="categoryTitle"/>
        <div v-for="y in cat.years" :key="y.id" class="yearContainer">
          <div v-html="y.year" class="year" />
          <div class="entryContainer">
            <template v-for="e in y.entries">

              <a v-if="e.entry_url.normalize()" v-html="e.entry_name" :href="e.entry_url" target="_blank" :key="e.id" />
              <span v-else v-html="e.entry_name" :key="e.id" />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "Navigation",
    components: {},
    created() {
        this.loadGeneral();
    },
    computed: {
        ...mapGetters({
            general: "general/general",
        }),
    },
    methods: {
        ...mapActions({
            loadGeneral: "general/loadGeneral",
        }),
    },
};
</script>
<style lang="scss" scoped>
.yearContainer {
    display: flex;
    .year {
        flex-basis: 100px;
    }
    .entryContainer {
        display: block;
    }
}
.aboutText {
  margin-bottom: $globalPadding;
  max-width: 500px;
} 
.category, .categoryTitle {
  margin-bottom: $globalPadding;
}
</style>
<style lang="scss">
p:first-of-type {
    margin-top: 0px;
}
p:last-of-type {
    margin-bottom: 0px;
}
</style>